
import get from 'lodash/get';
import { defineComponent, ref, computed, reactive } from 'vue';
import firebase from 'firebase/app';
import { TrackJS } from 'trackjs';
import { IonInput } from '@ionic/vue';
import { addGuestRecord, notifyHost } from '../services/checkin';
import { isEmail, isPhoneNumber } from '../utils/fieldValidation';
import { loading, toast } from '../utils/frameworkFeatures';
import useNavigation from '@/composables/useNavigation';
import { useMainStore } from '@/stores/main.store';
import { storeToRefs } from 'pinia';

export default defineComponent({
    name: 'CheckIn',
    components: {
        IonInput,
    },
    props: {
        headerTitle: String
    },
    setup(props, { emit }) {
        const isToastOpenRef = ref(false);
        const selectHost = ref(true);
        const store = useMainStore();

        const { guestModel } = storeToRefs(store);

        const formInvalidStates = reactive({
            email: false,
            phone: false
        });

        const { gotoVisitorHostSelect, gotoConfirmation } = useNavigation();

        function nextStep() {
            store.$patch({
                employeeModel: {},
                employeeId: '',
                userType: 'visitor',
            });

            if (store.checkInSettings.requireHost) {
                gotoVisitorHostSelect();
            } else {
                onGuestCheckIn();
            }
        }

        const nextdisabled = computed(() => {
            return store.checkInSettings.enableOptionalHost
                ? selectHost.value === true && !guestModel.value.employee
                : !guestModel.value.employee;
        });

        const onAutocompleteSelection = (data: Employee) => {
            if (data) {
                store.setGuestHost(data);
            } else {
                store.clearGuestHost();
            }
        };

        const isEmailValid = computed(() => {
            return (guestModel.value.email && isEmail(guestModel.value.email)) ||
                    (!guestModel.value.email && !store.checkInSettings.requireEmail);
        });

        const isPhoneValid = computed(() => {
            return (guestModel.value.phoneNumber && isPhoneNumber(guestModel.value.phoneNumber)) || 
                    (!guestModel.value.phoneNumber && !store.checkInSettings.requirePhoneNumber);
        });

        const guestFormIsValid = computed(() => {
            return (
                guestModel.value &&
                guestModel.value.firstName &&
                guestModel.value.firstName &&
                guestModel.value.lastName &&
                isEmailValid.value &&
                isPhoneValid.value
            );
        });

        const onGuestCheckIn = async () => {
            const loadingInstance = await loading({ message: 'Please wait...' });
            let payload, notifyData;

            const host = guestModel.value.employee && {
                firstName: guestModel.value.employee.firstName,
                lastName: guestModel.value.employee.lastName || '',
                email: guestModel.value.employee.email,
                userId: guestModel.value.employee.id,
                phoneNumber: guestModel.value.employee.phoneNumber || '',
                smsOptOut: guestModel.value.employee.smsOptOut || false,
            };

            try {
                const guestRecord = {
                    email: get(guestModel, 'value.email', ''),
                    firstName: guestModel.value.firstName,
                    lastName: guestModel.value.lastName,
                    phoneNumber: get(guestModel, 'value.phoneNumber', ''),
                    host: host || null,
                    customerId: store.customerId,
                    checkInMethod: 'mobile',
                    userType: 'guest',
                    locationId: store.tempLocationId,
                };

                notifyData = host && {
                    customerId: store.customerId,
                    email: get(guestModel, 'value.employee.email'),
                    hostName: `${guestModel.value.firstName} ${guestModel.value.lastName}`,
                    phoneNumber: get(guestModel, 'value.employee.phoneNumber', ''),
                    smsOptOut: guestModel.value.employee.smsOptOut || false,
                    hostUserId: guestModel.value.employee.id,
                };

                payload = {
                    ...guestRecord,
                    ...{
                        checkedInAt: firebase.firestore.Timestamp.now(),
                        checkedOutAt: '',
                    },
                };

                await addGuestRecord(store.customerId, payload);
                if (host) {
                    await notifyHost(notifyData);
                }

                isToastOpenRef.value = true;
                emit('on-hide-selection');
                gotoConfirmation('check-in', 1);
            } catch (err: any) {
                console.error(err);
                toast({ message: 'An error occurred and we were unable to check in' });
                TrackJS.console.error(`Failed to checkin at adding guest record (${err.message}): ${JSON.stringify({
                    payload,
                    notifyData
                })}`, 'onGuestCheckIn');
            } finally {
                loadingInstance && loadingInstance.dismiss();
            }
        };

        const resetForm = () => {
            emit('on-reset');
        };

        const goToHome = () => {
            store.clearGuestHost();
            resetForm();
        };

        return {
            formInvalidStates,
            goToHome,
            guestFormIsValid,
            guestModel,
            isToastOpenRef,
            nextStep,
            nextdisabled,
            onAutocompleteSelection,
            onGuestCheckIn,
            selectHost,
            store,
        };
    },
});
