
import { computed, defineComponent, ref } from 'vue';
import AutoComplete from '../components/AutoComplete.vue';
import { employeeAppSelections } from '@/config/app.config';
import useCheckIn from '@/composables/useCheckIn';
import { useMainStore } from '@/stores/main.store';

export default defineComponent({
    name: 'CheckIn',
    components: {
        AutoComplete,
    },
    props: {
        headerTitle: String,
    },
    emits: ['on-reset', 'on-hide-selection'],
    setup() {
        const { resetForm, isValidEmployee, onEmployeeSelect } = useCheckIn();
        const selectedEmployee = ref<Employee>(null);

        const store = useMainStore();

        const onAutocompleteSelection = (employee: Employee) => {
            selectedEmployee.value = employee;
        };

        const onLogin = () => {
            store.setEmployee(selectedEmployee.value);
            onEmployeeSelect();
        };

        const nextEnabled = computed(() => {
            return !!selectedEmployee.value;
        });

        return {
            onAutocompleteSelection,
            employeeAppSelections,
            onLogin,
            resetForm,
            isValidEmployee,
            nextEnabled,
        };
    },
});
