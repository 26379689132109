import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, renderList as _renderList, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04e57c0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ion-margin-bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_searchbar, {
      onIonChange: _ctx.onSearch,
      onIonClear: _ctx.onClear,
      value: _ctx.searchString,
      color: "light",
      debounce: "500"
    }, null, 8, ["onIonChange", "onIonClear", "value"]),
    (_ctx.noResultsMsg)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.noResultsMsg)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.noResultsMsg), 1))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.searchResults.length)
      ? (_openBlock(), _createBlock(_component_ion_content, {
          key: 1,
          style: {"min-height":"165px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list, {
              class: "employee-results",
              lines: "full"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults, (user) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: user.id,
                    onClick: ($event: any) => (_ctx.onSelect(user))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(user?.fullName), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}