
import EmployeeCheckin from '../components/EmployeeCheckin.vue';
import VisitorCheckin from '../components/VisitorCheckin.vue';
import useCheckIn from '@/composables/useCheckIn';
import { IonSegment, IonSegmentButton } from '@ionic/vue';
import { defineComponent, ref, computed, onMounted, watch, onUnmounted } from 'vue';
import { loading } from '@/utils/frameworkFeatures';
import { useMainStore } from '@/stores/main.store';
import { storeToRefs } from 'pinia';

export default defineComponent({
    name: 'Home',
    components: {
        EmployeeCheckin,
        IonSegment,
        IonSegmentButton,
        VisitorCheckin,
    },
    setup() {
        let loadingInstance: HTMLIonLoadingElement;
        const { onEmployeeSelect } = useCheckIn();
        const hideSelection = ref<boolean>(false);
        const componentKey = ref<number>(0);    // responsible for re-rendering a component
        const store = useMainStore();
        const { appReady } = storeToRefs(store);

        const onSegmentChanged = (evt: any) => {
            store.userType = evt.target.value;
        };

        const onHideUserSelection = (hide = false) => {
            hideSelection.value = hide;
        };

        const onReset = () => {
            componentKey.value += 1;
            store.userType = 'employee';
            hideSelection.value = false;
        };

        const hasEmployeeAndVisitorEnabled = computed((): boolean => store.customer?.hasEmployeeCheckin && store.customer?.hasVisitorCheckin);

        watch(appReady, async (val) => {
            if (val) {
                onEmployeeSelect();
                loadingInstance && loadingInstance.dismiss();
            }
        });

        // customer does not have chiking and desk booking enabled. Not action can be takin by the user
        const appNotConfigured = computed(() => {
            return !store.customer?.hasCheckin && !store.customer?.hasBooking;
        });

        onMounted(async () => {
            if (appReady.value) {
                onEmployeeSelect();
                loadingInstance && loadingInstance.dismiss();
            } else {
                loadingInstance  = await loading({ message: 'Initializing...' });
            }
        });
        
        onUnmounted(() => {
            setTimeout(() => {
                loadingInstance && loadingInstance.dismiss();
            }, 1000);
        });
        
        return {
            appNotConfigured,
            hideSelection,
            onSegmentChanged,
            onHideUserSelection,
            hasEmployeeAndVisitorEnabled,
            onReset,
            componentKey,
            appReady,
            store,
        };
    },
});
