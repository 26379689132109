import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_employee_checkin = _resolveComponent("employee-checkin")!
  const _component_visitor_checkin = _resolveComponent("visitor-checkin")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, null, _createSlots({ _: 2 }, [
    (!_ctx.appReady)
      ? {
          name: "body",
          fn: _withCtx(() => [])
        }
      : (_ctx.appNotConfigured)
        ? {
            name: "body",
            fn: _withCtx(() => [
              _createElementVNode("div", { class: "ion-margin ion-page ion-text-center ion-justify-content-center not-configured" }, [
                _createElementVNode("div", null, [
                  _createElementVNode("h4", null, "Turf Check-In and Desk Booking features are disabled."),
                  _createElementVNode("h5", null, "Please contact your IT administrator.")
                ])
              ])
            ])
          }
        : {
            name: "body",
            fn: _withCtx(() => [
              _createElementVNode("div", { class: "ion-margin ion-page ion-justify-content-center" }, [
                (_ctx.hasEmployeeAndVisitorEnabled)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (!_ctx.hideSelection)
                        ? (_openBlock(), _createElementBlock("div", { key: 0 }, [
                            _createElementVNode("h1", { class: "ion-text-center" }, "Who are you?"),
                            _createVNode(_component_ion_segment, {
                              onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSegmentChanged($event))),
                              mode: "md",
                              value: _ctx.store.userType
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_segment_button, {
                                  value: "employee",
                                  class: "ion-align-items-center"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("EMPLOYEE")
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_segment_button, {
                                  value: "visitor",
                                  class: "ion-align-items-center"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("VISITOR")
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.store.userType)
                        ? (_openBlock(), _createBlock(_component_ion_content, { key: 1 }, {
                            default: _withCtx(() => [
                              (_ctx.store.userType === 'employee')
                                ? (_openBlock(), _createBlock(_component_employee_checkin, {
                                    key: _ctx.componentKey,
                                    onOnReset: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onReset())),
                                    onOnHideSelection: _ctx.onHideUserSelection
                                  }, null, 8, ["onOnHideSelection"]))
                                : _createCommentVNode("", true),
                              (_ctx.store.userType === 'visitor')
                                ? (_openBlock(), _createBlock(_component_visitor_checkin, {
                                    key: _ctx.componentKey,
                                    onOnReset: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onReset())),
                                    onOnHideSelection: _ctx.onHideUserSelection
                                  }, null, 8, ["onOnHideSelection"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (!_ctx.hasEmployeeAndVisitorEnabled && (_ctx.store.customer?.hasEmployeeCheckin || _ctx.store.customer?.hasEmployeeCheckin && _ctx.store.customer?.hasDeskBooking))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("h1", { class: "ion-text-center" }, "Who are you?")
                      ]),
                      _createVNode(_component_ion_content, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_employee_checkin, {
                            key: _ctx.componentKey,
                            onOnReset: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onReset())),
                            onOnHideSelection: _ctx.onHideUserSelection
                          }, null, 8, ["onOnHideSelection"])
                        ]),
                        _: 1
                      })
                    ], 64))
                  : _createCommentVNode("", true),
                (!_ctx.hasEmployeeAndVisitorEnabled && _ctx.store.customer?.hasVisitorCheckin)
                  ? (_openBlock(), _createBlock(_component_ion_content, { key: 2 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_visitor_checkin, {
                          key: _ctx.componentKey,
                          headerTitle: "Who are you?",
                          onOnReset: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onReset())),
                          onOnHideSelection: _ctx.onHideUserSelection
                        }, null, 8, ["onOnHideSelection"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ])
          }
  ]), 1024))
}