import { TrackJS } from 'trackjs';
import { getCurrentInstance, ref, computed } from 'vue';
import useNavigation from '@/composables/useNavigation';
import { validateCheckinState, confirmCheckIn } from '@/services/checkin';
import { toast, loading, alert } from '@/utils/frameworkFeatures';
import { useMainStore } from '@/stores/main.store';
import { storeToRefs } from 'pinia';

// TODO: add types
export default function () {
    const store = useMainStore();
    const { gotoDeskBooking, gotoEmployeeHome, gotoConfirmation } = useNavigation();
    const { emit } = getCurrentInstance();
    const { employeeModel, userType, } = storeToRefs(store);

    const appType = ref<string>('');

    const isValidEmployee = computed(() => {
        return !(
            employeeModel.value &&
            employeeModel.value.email &&
            employeeModel.value.id
        );
    });

    const resetForm = () => emit('on-reset');

    const onCheckIn = async () => {
        const loadingInstance = await loading({ message: 'Please wait...' });

        try {
            // TODO: replace with turf core services later when functional with app (isStillCheckedIn)
            const userCheckInState = await validateCheckinState(
                store.customerId,
                employeeModel.value.email
            );

            const isStillCheckedIn = userCheckInState.find(
                (state: any) => !state.checkedOutAt
            );

            if (isStillCheckedIn) {
                alert({
                    header: 'Alert',
                    message: 'You\'re already checked in.',
                    buttons: ['OK'],
                });
                TrackJS.console.debug(`Failed to checkin at adding employee record. User is still checked in.: ${JSON.stringify({
                    employeeModel: employeeModel.value
                })}`, 'onCheckIn');
            } else {
                const payload = getPayload();
                await confirmCheckIn(payload);

                gotoConfirmation();
                emit('on-hide-selection', true);
            }
        } catch (err: any) {
            console.error(err.message);
            toast({ message: 'An error occurred and we were unable to check in' });
            TrackJS.console.error(`Failed to checkin at adding employee record (${err.message}): ${JSON.stringify(getPayload())}`, 'onCheckIn');
        } finally {
            loadingInstance && loadingInstance.dismiss();
        }
    };

    const getPayload = () => ({
        email: employeeModel.value.email,
        userId: employeeModel.value.id,
        phoneNumber: employeeModel.value.phoneNumber || '',
        smsOptOut: employeeModel.value.smsOptOut || false,
        customerId: store.customerId,
        checkInMethod: 'mobile',
        userType: 'employee',
        locationId: store.tempLocationId || '',
        firstName: employeeModel.value.firstName,
        lastName: employeeModel.value.lastName
    });

    const onEmployeeSelect = async () => {
        if (store.employeeId) {
            userType.value = 'employee';
            
            // checkin and desk booking configured
            if (store.customer.hasCheckin && store.customer.hasBooking) {
                if (store.tempDeskId) {
                    gotoDeskBooking(store.tempDeskId);
                } else {
                    gotoEmployeeHome();
                }
            } else 
            // no checkin, but desk booking
            if (!store.customer.hasCheckin && store.customer.hasBooking) {
                gotoDeskBooking(store.tempDeskId);
            } else 
            // checkin and no desk booking
            if (store.customer.hasCheckin && !store.customer.hasBooking) {
                gotoEmployeeHome();
            } else {
                // app is not configured for desk booking nor checkin
                console.error('Check In or desk booking is not enabled.');
            }
        }
    };

    const onAppSelectNext = (value: string) => {
        if (value === 'check-in') {
            onCheckIn();
        } else if (value === 'desk-booking') {
            gotoDeskBooking();
        }
    };

    return {
        appType,
        isValidEmployee,
        onAppSelectNext,
        onCheckIn,
        onEmployeeSelect,
        resetForm,
    };
}