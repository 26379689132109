
import { IonSearchbar } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { searchQuery } from '../services/checkin';
import { useMainStore } from '@/stores/main.store';

export default defineComponent({
    name: 'AutoComplete',
    components: {
        IonSearchbar,
    },
    emits: ['on-select'],
    setup(_, { emit }) {
        const store = useMainStore();
        const searchResults = ref([]);
        const noResultsMsg = ref('');
        const searchString = ref('');

        const onSearch = async (data: any) => {
            const name = data.target.value;

            if (name) {
                const d = await searchQuery(name, 'employee', store.customerId) as Customer[];
                searchString.value = name;
                noResultsMsg.value = d.length === 0 ? 'No results found.' : '';
                if (d.length === 1 && name === d[0]?.fullName) {
                    searchResults.value = [];
                } else {
                    searchResults.value = d;
                }
            } else { 
                searchResults.value = [];
                noResultsMsg.value = '';
                searchString.value = '';
            }
        };

        const onSelect = (user: Record<string, any>) => {
            emit('on-select', user);
            searchString.value = user.fullName;
            searchResults.value = [];
        };

        const onClear = () => {
            noResultsMsg.value = '';
            searchResults.value = [];
            emit('on-select', null);
        };

        return {
            noResultsMsg,
            searchResults,
            searchString,
            onSearch,
            onSelect,
            onClear,
        };
    },
});
